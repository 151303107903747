@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --twcb-scrollbar-width: -30px;
}

@layer components {
  .container {
    @apply sm:px-[2rem];
  }
}

@layer base {
  ::-moz-selection {
    @apply bg-flame-200 text-carbon-500;
  }

  ::selection {
    @apply bg-flame-200 text-carbon-500;
  }

  p {
    @apply leading-relaxed text-base text-carbon-400 font-copy;
  }

  ul {
    @apply leading-relaxed text-base text-carbon-400 font-copy;
  }

  h1 {
    @apply text-4xl md:text-5xl text-carbon-500 font-heading;
  }

  h2 {
    @apply text-3xl md:text-4xl text-carbon-500 font-heading;
  }

  h3 {
    @apply text-2xl md:text-3xl text-carbon-500 font-heading;
  }

  h4 {
    @apply text-xl md:text-2xl text-carbon-500 font-copy;
  }

  h5 {
    @apply text-lg md:text-xl text-carbon-500 font-copy;
  }

  .box-shadow-3d {
    box-shadow:
      -1px -1px 0px #cbcac9,
      -2px -2px 0px #cbcac9,
      -3px -3px 0px #cbcac9,
      -4px -4px 0px #cbcac9,
      -5px -5px 0px #cbcac9,
      -6px -6px 0px #cbcac9;

    @media (max-width: 800px) {
      box-shadow:
        -1px -1px 0px #cbcac9,
        -2px -2px 0px #cbcac9,
        -3px -3px 0px #cbcac9,
        -4px -4px 0px #cbcac9;

    }
  }
}
